<template>
  <div>
    <b-card-actions title="Filters" action-collapse>
      <b-row class="second-layer-margin">
        <b-col md="6" lg="4" xs="12">
          <v-select
              v-model="selectEmpId"
              :options="filterEmpIdOption"
              :reduce="(item) => item.id"
              v-on:input="loadLeave('user')"
              label="name"
              placeholder="Select Employee"
              class="mb-1 custom-font"
          >
            <template #option="data">
              <UserSelect :user="data" />
            </template>
          </v-select>
        </b-col>

        <b-col md="6" lg="4" xs="12">
          <v-select
              v-model="selectEmpTypeId"
              :options="filterEmpTypeIdOption"
              v-on:input="loadLeave('empType')"
              :reduce="(item) => item.id"
              label="name"
              placeholder="Select Employee Types"
              class="mb-1 custom-font"
          />
        </b-col>
        <b-col md="6" lg="4" xs="12">
          <v-select
              v-model="selectDivisionId"
              :options="filterDivisionIdOption"
              :reduce="(item) => item.id"
              v-on:input="loadLeave('division')"
              label="name"
              placeholder="Select Division"
              class="mb-1 custom-font"
          />
        </b-col>
        <b-col md="6" lg="4" xs="12">
          <v-select
              v-model="selectDepartmentId"
              :options="filterDepartmentIdOption"
              :reduce="(item) => item.id"
              v-on:input="loadLeave('department')"
              label="name"
              placeholder="Select Department"
              class="mb-1 custom-font"
          />
        </b-col>

        <b-col md="6" lg="4" xs="12">
          <v-select
              v-model="selectLeaveTypeId"
              :options="filterLeaveTypeIdOption"
              v-on:input="loadLeave('leaveType')"
              :reduce="(item) => item.id"
              label="name"
              placeholder="Select Leave Types"
              class="mb-1 custom-font"
          />
        </b-col>

      </b-row>
    </b-card-actions>
    <b-card class="app-calendar p-2">

      <FullCalendar :options="calendarOptions" class="full-calendar">
        <template v-slot:eventContent="arg">
          <template v-if="arg?.event?.extendedProps?.user">
            <template v-if="arg?.event?.extendedProps?.user?.avatar">
              <b-avatar
                  size="sm"
                  :src="arg?.event?.extendedProps?.user?.avatar"
                  class="mr-1"
              />
            </template>

            <span class="text-wrap">
              <b>{{ arg?.event?.extendedProps?.user?.name }}</b>
            </span>
          </template>
        </template>
      </FullCalendar>
      <div class="d-flex justify-content-center mt-2">
        <b-list-group horizontal="md">
          <!-- <b-list-group-item variant="success">
            Cake caramels donut danish muffin biscuit
          </b-list-group-item> -->
          <b-list-group-item variant="danger">
            <span class="bullet mr-1 bullet-danger" />
            <span>Leave</span>
          </b-list-group-item>
          <b-list-group-item variant="warning">
            <span class="bullet mr-1 bullet-warning" />
            <span>weekends</span>
          </b-list-group-item>
          <b-list-group-item class="danger bg-danger bg-lighten-2" variant="bg-danger bg-lighten-2">
            <span class="bullet mr-1 bullet-danger" />
            <span>Holidays</span>
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BBadge, BCard, BAvatar, BListGroup, BListGroupItem, BFormDatepicker, BCol, BRow } from 'bootstrap-vue'
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import multiMonthPlugin from "@fullcalendar/multimonth";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import UserSelect from '@/layouts/components/UserSelect.vue'

export default {
  name: "AttendanceCalenderView",
  components: {
    UserSelect,
    BRow,
    BCol,
    BFormDatepicker,
    BCardActions,
    BCard,
    BBadge,
    FullCalendar,
    BAvatar,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      filterEmpTypeIdOption: [],
      selectEmpTypeId: "",
      filterDivisionIdOption: [],
      selectDivisionId: "",
      filterDepartmentIdOption: [],
      selectDepartmentId: "",
      filterEmpIdOption: [],
      selectEmpId: "",
      filterLeaveTypeIdOption: [],
      selectLeaveTypeId: "",
      calendarOptions: {
        height: 750,
        contentHeight: 200,
        plugins: [multiMonthPlugin, dayGridPlugin, interactionPlugin],
        headerToolbar: {
          start: "prev,next, title",
          end: "multiMonthYear,dayGridMonth",
        },
        initialView: "multiMonthYear",
        multiMonthMaxColumns: 1,
        weekends: true,
        // dateClick: this.handleDateClick,
        dayMaxEvents: 2,
        // eventClassNames({ event: calendarEvent }) {
        //   const variantColor = {
        //     present: "success",
        //     holiday: "danger",
        //     leave: "danger",

        //     success: "success",
        //     danger: "danger",
        //     warning: "warning",
        //   };
        //   // console.log("eventClassNames", calendarEvent);
        //   // eslint-disable-next-line no-underscore-dangle
        //   const variantName =
        //     variantColor[calendarEvent?._def?.extendedProps?.variant];

        //   return [
        //     // Background Color
        //     `bg-${variantName} bg-lighten-2 text-white`,
        //   ];
        // },
        events: [],
        showNonCurrentDates: false,
      },
    };
  },
  async created() {

    try {

      const [employeeTypes, divisions, departments, employees, leaveTypes] =
          await Promise.all([
            this.getEmployeeTypes(),
            this.getDivisions(),
            this.getDepartments(),
            this.getActiveEmployees(),
            this.getLeaveType(),
          ]);

      // leave type
      this.filterLeaveTypeIdOption = (leaveTypes?.data?.data || []).map(
          (item) => {
            let name = item?.name;
            return {
              name,
              id: item?.id,
            };
          }
      );

      // employee type
      this.filterEmpTypeIdOption = (employeeTypes?.data?.data || []).map(
          (item) => {
            let name = item?.name;
            return {
              name,
              id: item?.id,
            };
          }
      );

      // department
      this.filterDepartmentIdOption = (departments?.data?.data || []).map(
          (item) => {
            let name = item?.name;

            return {
              name,
              divisionId: item?.division_id,
              id: item?.id,
            };
          }
      );

      // division
      this.filterDivisionIdOption = (divisions?.data?.data || []).map(
          (item) => {
            let name = item?.name;
            return {
              name,
              id: item?.id,
            };
          }
      );

      // employee
      this.filterEmpIdOption = (employees?.data?.data || []).map((item) => {
        return {
          avatar: item?.avatar,
          name: item?.name,
          email: item?.email,
          mobile: item?.mobile,
          employee_number: item?.employee_number,
          id: item?.id,
          departmentId: item?.department_id,
        }
      })

      this.loadItems()

    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },
  methods: {

    async loadItems() {
      const [settings, leaveEmployees, weekendsData, holidays] = await Promise.all([
        this.getSettings(),
        this.getLeaveEmployees(),
        this.getWeekends(),
        this.getHolidays()
      ]);

      // present employees
      const mapLeaveEmployees = leaveEmployees?.data?.data.map(function (item) {
        return {
          date: item?.date,
          textColor: "#ea5455",
          borderColor: "rgba(234, 84, 85, 0.12)",
          backgroundColor: "rgba(234, 84, 85, 0.12)",
          extendedProps: {
            user: {
              name: item?.user?.data?.name,
              avatar: item?.user?.data?.avatar,
            },
            // variant: "danger",
          },
        };
      });

      const mapHolidays = holidays?.data?.holidays.map(function (item) {
        return {
          date: item?.date,
          allDay: true,
          display: "background",
          textColor: "#FFFFFF",
          backgroundColor: "#ff0000",
          extendedProps: {
            user: {
              name: item?.title,
            },
            // variant: "danger",
          },
        };
      });

      const mapWeekends = weekendsData?.data?.data.map(function (item) {
        return {
          date: item?.date,
          allDay: true,
          overlap: true,
          display: "background",
          backgroundColor: "#ff9f43",
        };
      });

      this.calendarOptions.events.push(...mapLeaveEmployees, ...mapWeekends, ...mapHolidays);

    },

    async resetCalendar(){
      this.calendarOptions.events = [];
      this.loadItems();
    },

    async loadLeave(value) {
      if (value == "division") {
        this.selectDepartmentId = "";
        this.selectEmpId = "";
        if (this.selectDivisionId) {
          const departments = await this.getDepartments();

          this.filterDepartmentIdOption = (departments?.data?.data || []).map(
              (item) => {
                let name = item?.name;

                return {
                  name,
                  divisionId: item?.division_id,
                  id: item?.id,
                };
              }
          );

          this.filterDepartmentIdOption = this.filterDepartmentIdOption.filter(
              (item) => item?.divisionId == this.selectDivisionId
          );
        } else {
          const departments = await this.getDepartments();

          this.filterDepartmentIdOption = (departments?.data?.data || []).map(
              (item) => {
                let name = item?.name;

                return {
                  name,
                  divisionId: item?.division_id,
                  id: item?.id,
                };
              }
          );
        }
      }

      if (value == "department") {
        this.selectEmpId = "";
        if (this.selectDepartmentId) {
          const employees = await this.getActiveEmployees();

          this.filterEmpIdOption = (employees?.data?.data || []).map((item) => {
            return {
              avatar: item?.avatar,
              name: item?.name,
              email: item?.email,
              mobile: item?.mobile,
              employee_number: item?.employee_number,
              id: item?.id,
              departmentId: item?.department_id,
            }
          });

          this.filterEmpIdOption = this.filterEmpIdOption.filter(
              (item) => item?.departmentId == this.selectDepartmentId
          );
        } else {
          const employees = await this.getActiveEmployees();

          this.filterEmpIdOption = (employees?.data?.data || []).map((item) => {
            return {
              avatar: item?.avatar,
              name: item?.name,
              email: item?.email,
              mobile: item?.mobile,
              employee_number: item?.employee_number,
              id: item?.id,
              departmentId: item?.department_id,
            };
          });
        }
      }

      this.resetCalendar();
    },

    async getLeaveType() {
      return await this.$api.get("api/leave-types");
    },
    async getActiveEmployees() {
      return await this.$api.get("api/users/active-all");
    },
    async getDivisions() {
      return await this.$api.get("api/divisions/all");
    },
    async getEmployeeTypes() {
      return await this.$api.get("api/employee-types/all");
    },
    async getDepartments() {
      return await this.$api.get("api/departments/all");
    },

    async getLeaveEmployees() {
      return await this.$api.get(
        "api/attendances/leave-employees?include=user", {
            params: {
              filterLeaveTypeId: this.selectLeaveTypeId,
              filterDivisionId: this.selectDivisionId,
              filterEmpTypeId: this.selectEmpTypeId,
              filterDepartmentId: this.selectDepartmentId,
              filterEmpId: this.selectEmpId,
            }
          }
      );
    },
    async getHolidays() {
      return await this.$api.get(
        "api/holidays/calendar"
      );
    },
    async getWeekends() {
      return await this.$api.get(
        "api/weekends/all"
      );
    },
    // async getPresentEmployees() {
    //   return await this.$api.get(
    //     "api/attendances/present-employees?include=user"
    //   );
    // },
    async getSettings() {
      return await this.$api.get("api/settings?select=weekends");
    },
    // toggleWeekends: function () {
    //   this.calendarOptions.weekends = !this.calendarOptions.weekends; // toggle the boolean!
    // },
    // handleDateClick: function (arg) {
    //   console.log("handleDateClick", arg);
    //   alert("date click! " + arg.dateStr);
    // },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.fc-bg-event{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
