var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card-actions', {
    attrs: {
      "title": "Filters",
      "action-collapse": ""
    }
  }, [_c('b-row', {
    staticClass: "second-layer-margin"
  }, [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.filterEmpIdOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Select Employee"
    },
    on: {
      "input": function input($event) {
        return _vm.loadLeave('user');
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('UserSelect', {
          attrs: {
            "user": data
          }
        })];
      }
    }]),
    model: {
      value: _vm.selectEmpId,
      callback: function callback($$v) {
        _vm.selectEmpId = $$v;
      },
      expression: "selectEmpId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.filterEmpTypeIdOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Select Employee Types"
    },
    on: {
      "input": function input($event) {
        return _vm.loadLeave('empType');
      }
    },
    model: {
      value: _vm.selectEmpTypeId,
      callback: function callback($$v) {
        _vm.selectEmpTypeId = $$v;
      },
      expression: "selectEmpTypeId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.filterDivisionIdOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Select Division"
    },
    on: {
      "input": function input($event) {
        return _vm.loadLeave('division');
      }
    },
    model: {
      value: _vm.selectDivisionId,
      callback: function callback($$v) {
        _vm.selectDivisionId = $$v;
      },
      expression: "selectDivisionId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.filterDepartmentIdOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Select Department"
    },
    on: {
      "input": function input($event) {
        return _vm.loadLeave('department');
      }
    },
    model: {
      value: _vm.selectDepartmentId,
      callback: function callback($$v) {
        _vm.selectDepartmentId = $$v;
      },
      expression: "selectDepartmentId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.filterLeaveTypeIdOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Select Leave Types"
    },
    on: {
      "input": function input($event) {
        return _vm.loadLeave('leaveType');
      }
    },
    model: {
      value: _vm.selectLeaveTypeId,
      callback: function callback($$v) {
        _vm.selectLeaveTypeId = $$v;
      },
      expression: "selectLeaveTypeId"
    }
  })], 1)], 1)], 1), _c('b-card', {
    staticClass: "app-calendar p-2"
  }, [_c('FullCalendar', {
    staticClass: "full-calendar",
    attrs: {
      "options": _vm.calendarOptions
    },
    scopedSlots: _vm._u([{
      key: "eventContent",
      fn: function fn(arg) {
        var _arg$event, _arg$event$extendedPr, _arg$event2, _arg$event2$extendedP, _arg$event2$extendedP2, _arg$event3, _arg$event3$extendedP, _arg$event3$extendedP2, _arg$event4, _arg$event4$extendedP, _arg$event4$extendedP2;
        return [arg !== null && arg !== void 0 && (_arg$event = arg.event) !== null && _arg$event !== void 0 && (_arg$event$extendedPr = _arg$event.extendedProps) !== null && _arg$event$extendedPr !== void 0 && _arg$event$extendedPr.user ? [arg !== null && arg !== void 0 && (_arg$event2 = arg.event) !== null && _arg$event2 !== void 0 && (_arg$event2$extendedP = _arg$event2.extendedProps) !== null && _arg$event2$extendedP !== void 0 && (_arg$event2$extendedP2 = _arg$event2$extendedP.user) !== null && _arg$event2$extendedP2 !== void 0 && _arg$event2$extendedP2.avatar ? [_c('b-avatar', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "src": arg === null || arg === void 0 ? void 0 : (_arg$event3 = arg.event) === null || _arg$event3 === void 0 ? void 0 : (_arg$event3$extendedP = _arg$event3.extendedProps) === null || _arg$event3$extendedP === void 0 ? void 0 : (_arg$event3$extendedP2 = _arg$event3$extendedP.user) === null || _arg$event3$extendedP2 === void 0 ? void 0 : _arg$event3$extendedP2.avatar
          }
        })] : _vm._e(), _c('span', {
          staticClass: "text-wrap"
        }, [_c('b', [_vm._v(_vm._s(arg === null || arg === void 0 ? void 0 : (_arg$event4 = arg.event) === null || _arg$event4 === void 0 ? void 0 : (_arg$event4$extendedP = _arg$event4.extendedProps) === null || _arg$event4$extendedP === void 0 ? void 0 : (_arg$event4$extendedP2 = _arg$event4$extendedP.user) === null || _arg$event4$extendedP2 === void 0 ? void 0 : _arg$event4$extendedP2.name))])])] : _vm._e()];
      }
    }])
  }), _c('div', {
    staticClass: "d-flex justify-content-center mt-2"
  }, [_c('b-list-group', {
    attrs: {
      "horizontal": "md"
    }
  }, [_c('b-list-group-item', {
    attrs: {
      "variant": "danger"
    }
  }, [_c('span', {
    staticClass: "bullet mr-1 bullet-danger"
  }), _c('span', [_vm._v("Leave")])]), _c('b-list-group-item', {
    attrs: {
      "variant": "warning"
    }
  }, [_c('span', {
    staticClass: "bullet mr-1 bullet-warning"
  }), _c('span', [_vm._v("weekends")])]), _c('b-list-group-item', {
    staticClass: "danger bg-danger bg-lighten-2",
    attrs: {
      "variant": "bg-danger bg-lighten-2"
    }
  }, [_c('span', {
    staticClass: "bullet mr-1 bullet-danger"
  }), _c('span', [_vm._v("Holidays")])])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }